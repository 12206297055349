import React, { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ReactSVG from 'react-svg'

const getAppStoreDetials = (scheme: string) => {
  switch (scheme) {
    case 'dtx-astz':
      return {
        appName: 'Humanitas Percorsi-Cardiologia',
        iosLink: 'https://apps.apple.com/us/app/id1531497524',
        androidLink:
          'https://play.google.com/store/apps/details?id=it.humanitas.dtx_astz',
      }
    case 'dtx-nvrt':
      return {
        appName: 'Humanitas Percorsi-Reuma',
        iosLink: 'https://apps.apple.com/us/app/id1532378882',
        androidLink:
          'https://play.google.com/store/apps/details?id=it.humanitas.dtx_nvrt',
      }
    default:
      return {
        appName: 'Humanitas Percorsi',
        iosLink: 'https://apps.apple.com/us/app/id1483346658',
        androidLink:
          'https://play.google.com/store/apps/details?id=it.humanitas.percorsi',
      }
  }
}

const Authenticate: FC = () => {
  const { email, key } = useParams()
  useEffect(() => {
    window.location.replace(
      `${
        process.env.REACT_APP_APP_HOST
      }:///account-activation?email=${encodeURIComponent(email)}&key=${key}`
    )
  }, [])

  const { appName, iosLink, androidLink } = getAppStoreDetials(
    process.env.REACT_APP_APP_HOST
  )

  return (
    <div className='flex column justify-center align-center container'>
      <div className='flex row logoWrapper'>
        <img src={require('assets/img/logo.png')} />
      </div>
      <h3>
        {`You should open this link from your mobile device with ${appName} app installed`}
      </h3>
      <div className={'flex row'}>
        <a href={iosLink}>
          <ReactSVG
            svgStyle={{ width: `180px`, height: `200px`, marginRight: '20px' }}
            src={require('assets/img/app-store-btn.svg')}
          />
        </a>
        <a href={androidLink}>
          <ReactSVG
            svgStyle={{ width: `200px`, height: `200px`, marginLeft: '20px' }}
            src={require('assets/img/play-store-btn.svg')}
          />
        </a>
      </div>
    </div>
  )
}

export default Authenticate
